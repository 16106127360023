import axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';




export async function SubmitEInvoice(auth, model, data, type) {
  if (type == undefined) {
    model = model
  } else if (type == "barge") {
    model = model + "-barge"
  } else {
    model = model
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/submit-e-invoicing2",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
 
}
export async function SubmitEInvoice2(auth, model, data, type) {
  if (type == undefined) {
    model = model
  } else if (type == "barge") {
    model = model + "-barge"
  } else {
    model = model
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/submit-e-invoicing2",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
 
}
export async function GetSubmissionEInvoice(auth, model, data, type) {
  if (type == undefined) {
    model = model
  } else if (type == "barge") {
    model = model + "-barge"
  } else {
    model = model
  }
  try {
    const response = await $.ajax({
      type: "POST",
      url: auth.globalHost + auth.globalPathLink + model + "/get-submission-detail",
      dataType: "json",
      headers: {
        "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
      },
      data: data
    });

    return response;

  } catch (jqXHR) {
    if (jqXHR.status === 500) {
      alert(jqXHR.responseJSON.message)
      $(".PageOverlay").addClass("d-none")
      return jqXHR
      throw new Error("Server error: 500");
    } else {
      throw jqXHR;
    }
  }
 
}

export async function CancelEInvoice(auth, model, data,type) {
  if (type == undefined) {
    model = model
  } else if (type == "barge") {
    model = model + "-barge"
  } else {
    model = model
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/cancel-e-invoicing",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
 
}



export async function GetUpdateData(id, auth, model) {


  var newModel = model == "port" ? "area" : model
  newModel = newModel == "terminal" ? "port-details" : newModel
  const response = await axios.get(auth.globalHost + auth.globalPathLink + newModel + "/get-update-data?id=" + id, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function ShutOut(id, auth, model) {


  var newModel = model == "port" ? "area" : model
  newModel = newModel == "terminal" ? "port-details" : newModel
  const response = await axios.get(auth.globalHost + auth.globalPathLink + newModel + "/get-update-data?id=" + id, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function GetAuthApprovalData(auth, model) {

  const response = await axios.get(auth.globalHost + auth.globalPathLink + model + "/get-auth-approval-data", {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}


export async function CreateData(auth, model, formData) {
  var newModel = model == "port" ? "area" : model
  newModel = newModel == "terminal" ? "port-details" : newModel
  const response = await axios.post(auth.globalHost + auth.globalPathLink + newModel + "/create-data", formData, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function QuickAddCharges(auth, model, formData) {
  var newModel = model == "port" ? "area" : model
  newModel = newModel == "terminal" ? "port-details" : newModel
  const response = await axios.post(auth.globalHost + auth.globalPathLink + newModel + "/quick-add", formData, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function BatchUpateCharges(auth, model, formData) {
  var newModel = model == "port" ? "area" : model
  newModel = newModel == "terminal" ? "port-details" : newModel
  const response = await axios.post(auth.globalHost + auth.globalPathLink + newModel + "/batch-update", formData, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}


export async function GetBatchEffectedDocs(auth, model, formData,type) {
  var newModel = model == "port" ? "area" : model
  newModel = newModel == "terminal" ? "port-details" : newModel
  const response = await axios.post(auth.globalHost + auth.globalPathLink + newModel + "/get-batch-update-effected-document?type="+type, formData, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function BatchUpdateEffectedDocument(auth, model, formData,type) {
  var newModel = model == "port" ? "area" : model
  newModel = newModel == "terminal" ? "port-details" : newModel
  const response = await axios.post(auth.globalHost + auth.globalPathLink + newModel + "/batch-update-effected-document?type="+type, formData, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function GenerateConsolidateSalesinvoice(auth, model, formData) {
  var newModel = model == "port" ? "area" : model
  newModel = newModel == "terminal" ? "port-details" : newModel
  const response = await axios.post(auth.globalHost + auth.globalPathLink + newModel + "/generate-consolidate-sales-invoice", formData, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}
export async function GenerateConsolidateCreditNote(auth, model, formData) {
  var newModel = model == "port" ? "area" : model
  newModel = newModel == "terminal" ? "port-details" : newModel
  const response = await axios.post(auth.globalHost + auth.globalPathLink + newModel + "/generate-consolidate-sales-credit-note", formData, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}
export async function GenerateConsolidateDebitNote(auth, model, formData) {
  var newModel = model == "port" ? "area" : model
  newModel = newModel == "terminal" ? "port-details" : newModel
  const response = await axios.post(auth.globalHost + auth.globalPathLink + newModel + "/generate-consolidate-sales-debit-note", formData, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}



export async function SavePublicData(auth, model, formData) {

  const response = await axios.post(auth.globalHost + auth.globalPathLink + model + "/public-save-data", formData)
  return response
}

export async function UpdateData(id, auth, model, formData) {

  var newModel = model == "port" ? "area" : model
  newModel = newModel == "terminal" ? "port-details" : newModel
  const response = await axios.post(auth.globalHost + auth.globalPathLink + newModel + "/update-data?id=" + id, formData, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function CheckFloatingCharges(id, auth, model) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/check-floating-charges?id=" + id,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}

export async function UpdateFloatingCharges(id, auth, model) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/update-floating-charges?id=" + id,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}

export async function AuthApproval(auth, model, formData) {

  const response = await axios.post(auth.globalHost + auth.globalPathLink + model + "/auth-approval", formData, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}


export async function sendEmailManifest(auth, formData) {
  const response = await axios.post(auth.globalHost + auth.globalPathLink + "manifest/send-email", formData, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}


export async function ChangePassword(auth, formData) {
  const response = await axios.post(auth.globalHost + auth.globalPathLink + "site/change-password", formData, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

//use when pass = default pass
export async function ChangePasswordDefault(auth, info, formData) {
  const response = await axios.post(auth.globalHost + auth.globalPathLink + "site/change-password", formData, {
    auth: {
      username: info.username,
      password: info.access_token,
    },
  })
  return response
}

export function ToastNotify(type, message, autoCloseTime) {
  toast[type](message, {
    position: "bottom-right",
    autoClose: autoCloseTime ? autoCloseTime : 3000,
    hideProgressBar: true,
    closeOnClick: true,
    progress: undefined,
  });
}


export function ControlOverlay(value) {
  value === true ? $(".PageOverlay").removeClass("d-none") : $(".PageOverlay").addClass("d-none")
}

export function ControlOverlayContainerCard(value) {
  value === true ? $(".PageOverlayForContainerCard").removeClass("d-none") : $(".PageOverlayForContainerCard").addClass("d-none")
}

export function ControlOverlayAccessModal(value) {
  value === true ? $(".PageOverlayForAccessModal").removeClass("d-none") : $(".PageOverlayForAccessModal").addClass("d-none")
}

export async function GetAllDropDown(data, auth, async) {

  if (async == undefined) {
    async = true;
  }

  const response = await $.ajax({
    type: "POST",
    async: async,
    url: auth.globalHost + auth.globalPathLink + "universal/get-all-dropdown-data",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { Tables: data }

  });
  return response
}

export async function UpdateToPastDoc(data, auth, model) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "universal/update-to-past-document?DocumentName="+model+"&DocumentUUID="+data,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}


//get companies and its branch data
export async function GetCompaniesData(data, auth) {

  var filters = {
    "CompanyUUID": data
  };

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "company/get-companies",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { filters: filters },

  });
  return response
}

//get companies and its branch data
export async function GetReceiptSalesINvoice(data, auth) {

  var filters = {
    "SalesInvoice.BranchCode": data
  };

  var orderBy = {
    "SalesInvoice.DocDate": "SORT_ASC"
  };

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "sales-invoice/get-sales-invoice",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { BranchCode: data, },

  });
  return response
}

export async function GetOverpaidReceipt(data, auth) {
  
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "customer-payment/get-overpaid-receipt",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { BranchCode: data, },

  });
  return response
}

export async function CreateBC(id, auth, model, type) {
  var newModel = model == "port" ? "area" : model
  newModel = newModel == "terminal" ? "port-details" : newModel
  if (type == undefined) {
    newModel = newModel;
  } else if (type == "barge") {
    newModel = newModel + "-barge";
  } else {
    newModel = newModel
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + newModel + "/create-booking-confirmation",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: {
      BookingReservationUUIDs: id
    }
  });
  return response
}

export async function ConfirmSalesInvoice(id, auth, model, type,status) {
  var newModel = model == "port" ? "area" : model
  newModel = newModel == "terminal" ? "port-details" : newModel


  if (type == undefined) {
    newModel = newModel;
  } else if (type == "barge") {
    newModel = newModel + "-barge";
  } else {
    newModel = newModel
  }
  
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + newModel + `/convert-sales-invoice?status=${status}`,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: {
      SalesInvoiceUUIDs: id
    }
  });
  return response
}


export async function ConfirmThirdParty(auth, model, data,type) {
  var newModel = model == "port" ? "area" : model
  newModel = newModel == "terminal" ? "port-details" : newModel
  if (type == undefined) {
    newModel = newModel;
  } else if (type == "barge") {
    newModel = newModel + "-barge";
  } else {
    newModel = newModel
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + newModel + "/confirm-third-party",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}

export async function GetCOCCompaniesData(data, auth) {

  var filters = {
    "CompanyName": data
  };

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "company/get-companies",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { filters: filters },

  });
  return response
}
//get branch data
export async function GetBranchData(value, auth) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "company-branch/get-company-branch-by-id?id=" + value,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function CheckPermitAttachment(data, auth) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "manifest/check-permit-attachment",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: {
      BillOfLadingUUIDs: data,
    }
  });
  return response
}


export async function Throw(auth, model, data, type) {
  if (type == undefined) {
    model = model
  } else if (type == "barge") {
    model = model + "-barge"
  } else {
    model = model
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/throw2",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}

export async function Remove(auth, model, data, type) {
  if (type == undefined) {
    model = model
  } else if (type == "barge") {
    model = model + "-barge"
  } else {
    model = model
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/remove2",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}

export async function Approved(auth, model, data) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/approved",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}

export async function RejectUser(auth, model, data) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/reject",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}

export async function Suspend(auth, model, data) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/suspend",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}

export async function Reset(auth, model, data) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/reset",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}

export async function Verify(auth, model, data, type) {

  if (type == undefined) {
    model = model
  } else if (type == "barge") {
    model = model + "-barge"
  } else {
    model = model
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/verify?status=Verify",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}

export async function VerifyCreditTerm(auth, model, data, type) {

  if (type == undefined) {
    model = model
  } else if (type == "barge") {
    model = model + "-barge"
  } else {
    model = model
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/verify-credit-term",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}

export async function AprrovedDNDContainer(auth, model, data, type) {

  if (type == undefined) {
    model = model
  } else if (type == "barge") {
    model = model + "-barge"
  } else {
    model = model
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/verify-d-n-d?",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}


export async function cancelApprovedReject(auth, model, data, type) {
  if (type == undefined) {
    model = model
  } else if (type == "barge") {
    model = model + "-barge"
  } else {
    model = model
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/verify?status=Pending",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}


export async function Reject(auth, model, data, type) {
  if (type == undefined) {
    model = model
  } else if (type == "barge") {
    model = model + "-barge"
  } else {
    model = model
  }

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/verify?status=Reject",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}

export async function RejectRecord(auth, model, data) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/reject-record",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}

export async function GetCompanyByShipOrBox(data, auth) {


  const response = await $.ajax({
    type: "GET",
    url: auth.globalHost + auth.globalPathLink + "company/get-companies-by-ship-or-box-operator?Operator=" + data,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}


export function createCookie(name, value, days) {
  var expires;
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toGMTString();

  } else {
    expires = "";
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

export function getCookie(c_name) {
  if (document.cookie.length > 0) {
    var c_start = document.cookie.indexOf(c_name + "=");
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1;
      var c_end = document.cookie.indexOf(";", c_start);
      if (c_end == -1) {
        c_end = document.cookie.length;
      }
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return "";
}

export async function GetChargesByAreaContainer(data, auth, async) {

  if (async == undefined) {
    async = true;
  }

  const response = await $.ajax({
    type: "POST",
    async: async,
    url: auth.globalHost + auth.globalPathLink + "charges/get-charges-by-area2?ContainerType=" + data.ContainerType,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { POL: data.POL, POD: data.POD, StartDate: data.StartDate, EndDate: data.EndDate, CurrencyType: data.CurrencyType },

  });
  return response
}

export async function GetGroupChargesByAreaContainer(data, containerType, auth, async) {

  if (async == undefined) {
    async = true;
  }

  const response = await $.ajax({
    type: "POST",
    async: async,
    url: auth.globalHost + auth.globalPathLink + "charges/get-charges-by-area3?ContainerType=" + containerType,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { POL: data.POL, POD: data.POD, StartDate: data.StartDate, EndDate: data.EndDate, CurrencyType: data.CurrencyType },

  });
  return response
}

export async function getAllPortDetails(auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "port-details/get-port-details",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}



export async function getPortDetails(data, auth) {

  var filters = {
    "PortCode": data
  };

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "port-details/get-port-details",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { filters: filters },

  });
  return response
}

export async function GetPortDetailsByFilters(filters, auth) {

  // var filters = {
  //   "PortCode": data
  // };

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "port-details/get-port-details",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { filters: filters },

  });
  return response
}


export async function getPortDetailsById(data, auth) {
  const response = await $.ajax({
    type: "GET",
    url: auth.globalHost + auth.globalPathLink + "port-details/get-port-details-by-id?id=" + data,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },


  });
  return response
}

export async function GetChargesById(data, auth) {

  const response = await $.ajax({
    type: "GET",
    url: auth.globalHost + auth.globalPathLink + "charges/get-charges-by-id?id=" + data,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}

export async function GetBCChargesDescrption(data, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "credit-note/get-b-c-charges-description",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { ChargesData: data }

  });
  return response
}


export async function getCurrencyRate(filters, auth) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "currency-rate/get-currency-rate",
    dataType: "json",
    data: { filters: filters },
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}

export async function getGLCode(filters, auth) {
  if(filters.Voyage){
    var voyageUUID=(filters.Voyage).split('@')[0]
  }else{
    var voyageUUID=""
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + `charges/get-g-l-code?POL=${filters.POL}&POD=${filters.POD}&Voyage=${voyageUUID}&Charges=${filters.Charges}&Currency=${filters.Currency}&ContainerType=${filters.ContainerType}&VesselUUID=${filters.VesselUUID}&BargeUUID=${filters.BargeUUID}`,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}

export async function GetTaxCodeById(data, auth) {

  const response = await $.ajax({
    type: "GET",
    url: auth.globalHost + auth.globalPathLink + "tax-code/get-tax-code-by-id?id=" + data,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}

export async function GetCreditTerm(data, auth) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "credit-term/get-credit-terms",
    dataType: "json",
    data: { filters: data },
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}

export async function getCompanyBranches(data, auth) {
  var filters = {
    "Company": data
  };

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "company-branch/get-company-branches",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { filters: filters },

  });
  return response
}

export async function getAreas(data, auth) {
  var filters = {
    "AreaUUID": data
  };

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "area/get-areas",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { filters: filters },

  });
  return response
}

export async function getAreaById(data, auth) {
  var filters = {
    "Area.Valid": 1,
  };
  const response = await $.ajax({
    type: "GET",
    url: auth.globalHost + auth.globalPathLink + "area/get-area-by-id?id=" + data,
    dataType: "json",
    data: { filters: filters },
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}

export async function getUNNumberByID(data, auth) {
  const response = await $.ajax({
    type: "GET",
    url: auth.globalHost + auth.globalPathLink + "u-n-number/get-u-n-number-by-id?id=" + data,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}

export async function getUNNumberByMultipleID(data, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "u-n-number/get-u-n-number-by-multiple-id",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,

  });
  return response
}

export async function getHSCodeByID(data, auth) {
  const response = await $.ajax({
    type: "GET",
    url: auth.globalHost + auth.globalPathLink + "h-s-code/get-h-s-code-by-id?id=" + data,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}

export async function getTransferVoyageEffectedDocument(id, auth) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "booking-reservation/effected-documents?id=" + id,
    dataType: "json",
    async: false,
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}

export async function TransferVoyageBR(id, FromVoyageTranshipment, qtNo, auth, type) {
  var Link;
  if (type == undefined) {
    Link = "booking-reservation";
  } else if (type == "barge") {
    Link = "booking-reservation-barge";
  } else {
    Link = "booking-reservation";
  }

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + Link + "/transfer-voyage?id=" + id,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: {
      FromVoyageTranshipment,
      Quotation: qtNo,
    },

  });
  return response
}



export async function getCompanyDataByCompanyType(data, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "company/get-company-data-by-company-type",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { CompanyType: data },

  });
  return response
}

export async function getCompanyDataByID(data, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "company/get-company-by-id?id=" + data,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { filters: { "CompanyUUID": data } },

  });
  return response
}

export async function getContainerByContainerTypeDepot(data, auth) {

  const response = await $.ajax({
    type: "GET",
    url: auth.globalHost + auth.globalPathLink + "container/get-container-by-container-type?Depot=" + data,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}

export async function getBookingReservationContainerById(data, auth) {

  const response = await $.ajax({
    type: "GET",
    url: auth.globalHost + auth.globalPathLink + "booking-reservation/get-booking-reservation-containers?id=" + data,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}


export async function getContainers(data, filters, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "container/get-containers",
    dataType: "json",
    data: { filters: filters },
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}
export async function getReplaceContainers(auth) {

  const response = await $.ajax({
    type: "GET",
    url: auth.globalHost + auth.globalPathLink + "container-release/get-replace-container",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}

export async function getContainersWithModal(url, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + url,
    dataType: "json",
    // data: { filters: filters },
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}


export async function getConsolidateInvoiceWithModal(url, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + url,
    dataType: "json",
    // data: { filters: filters },
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}



export async function getContainerType(data, auth) {
  var filters = {
    "ContainerTypeUUID": data
  };

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "container-type/get-container-type",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { filters: filters },

  });
  return response
}


export async function GetRoutePointByRouteId(data, auth) {

  const response = await $.ajax({
    type: "GET",
    url: auth.globalHost + auth.globalPathLink + "route/get-route-points-by-route-id?id=" + data,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}



export async function getUserRules(data, auth) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "user/get-user-rules?id=" + data,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}

export async function GetUserDetails(auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "user/get-users",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { "filters[id]": auth.authInfo.id },

  });
  return response
}

export async function GetCompanyDropdown(CompanyType, params, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "company/get-company-dropdown",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { "CompanyType": CompanyType, "param": params },

  });
  return response
}

export async function GetCompanyBranchDropdown(params, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "company-branch/get-company-branch-dropdown",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { "param": params },

  });
  return response
}

export async function GetCompanyBranches(filters, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "company-branch/get-company-branches",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { "filters": filters },

  });
  return response
}

export async function specialMovementEventHandler(combinedIds, containerIDs, auth, status, depot) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "special-movement/update-data",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: {
      ContainerUUIDs: containerIDs,
      BRContainerIDs: combinedIds,
      Status: status,
      DepotID: depot,

    },

  });
  return response
}

export async function releaseContainer(data, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "container-release/release-container",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { "BRContainerIDs": data },

  });
  return response
}

export async function releaseUpdateCROList(data, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "container-release/get-available-c-r-o",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { "ContainerUUIDs": data },

  });
  return response
}

export async function releaseUpdateCRO(data, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "container-release/confirm-and-release",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { "ContainerUUIDs": data },

  });
  return response
}



export async function releaseReplaceContainer(data, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "container-release/replace-release-container",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { "ContainerReplaced": data },

  });
  return response
}

export async function loadContainer(data, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "container-loaded/loaded-container",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { "BRContainerIDs": data },

  });
  return response
}

export async function verifiedContainer(data, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "container-transshipment/verified-container",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { "BRContainerIDs": data },

  });
  return response
}

export async function revertContainer(data, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "container-transshipment/revert-container",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { "BRContainerIDs": data },

  });
  return response
}

export async function dischargeContainer(data, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "container-discharged/discharged-container",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { "BRContainerIDs": data },

  });
  return response
}

export async function gateOutContainer(data, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "container-gate-out/gate-out-container",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { "BRContainerIDs": data },

  });
  return response
}

export async function depoGateInOutContainer(data, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "container-in-out/in-out-container",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { "BRContainerIDs": data },

  });
  return response
}


export async function receivedContainer(data, depot, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "container-receive/received-container",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: {
      "BRContainerIDs": data,
      DepotID: depot,
    },

  });
  return response
}


export async function gateInContainer(data, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "container-gate-in/gate-in-container",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { "BRContainerIDs": data },

  });
  return response
}

export async function GetCompanyContacts(filters, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "company-contact/get-company-contacts",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { "filters": filters },

  });
  return response
}

export async function GetPortDetailsByUserPort(data, auth) {

  const response = await $.ajax({
    type: "GET",
    url: auth.globalHost + auth.globalPathLink + "port-details/get-port-details-by-user-port?UserPort=" + data,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}

export async function manifestCollectAttachment(data, status, auth,type) {
  var latestData={}
  var subLink;
  if(type=="DO"){
     latestData={ DeliveryOrderUUIDs: data, Status: status}
     subLink="manifest/collect-attachment-d-o"
  }else{
     latestData={ BillOfLadingUUIDs: data, Status: status}
       subLink="manifest/collect-attachment"
  }

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + subLink,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data:latestData
  });
  return response
}
export async function getFindContainerStatus(filters, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "real-time-tracking/find-container-status",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: {
      ContainerCode: filters.ContainerCode,
      BL: filters.BL,
      BR: filters.BR
    },

  });
  return response
}

export async function getCheckCompany(filters, CompanyUUID, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "company/check-company",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { filters: filters, CompanyUUID: CompanyUUID }


  });
  return response
}

export async function getCheckCharges(filters, auth) {

  const response = await $.ajax({
    type: "POST",
    async: false,
    url: auth.globalHost + auth.globalPathLink + "charges/check-charges",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { filters: filters }


  });
  return response
}

export async function getCheckTariff(filters, auth) {

  const response = await $.ajax({
    type: "POST",
    async: false,
    url: auth.globalHost + auth.globalPathLink + "tariff/check-tariff",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { filters: filters }


  });
  return response
}

export async function GetCompanyContactsByCompanyBranch(param, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "company-contact/get-company-contacts-by-company-branch",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { "param": param },

  });
  return response
}

export async function GetCompany(portCode, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "credit-limit-report/get-company",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { "PortCode": portCode },

  });
  return response
}

export async function getEffectedDocuments(data, accPort,auth) {

  const response = await $.ajax({
    type: "POST",
    async: false,
    url: auth.globalHost + auth.globalPathLink + "charges/effected-documents",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { "arrayCharges": JSON.stringify(data),"AccessPort":accPort?.join(",") },

  });
  return response
}

export async function getEffectedDocumentsConfirm(data, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "charges/confirm",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { "arrayUUIDs": data },

  });
  return response
}



export async function getContainerVerifyGrossMassListDataByPort(auth, async) {
  if (async == undefined) {
    async = true;
  }

  const response = await $.ajax({
    type: "POST",
    async: async,
    url: auth.globalHost + auth.globalPathLink + "container-verify-gross-mass/get-list-data-by-user-has-port",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}

export async function FindVoyagesWithPolPod(data, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "voyage/find-voyages-with-pol-pod",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: {
      POL: data.POL,
      POD: data.POD,
      Quotation:data.Quotation,
      DocDate: data.DocDate,
      LastValidDate: data.LastValidDate,
      POTVoyageUUIDs: data.POTVoyageUUIDs,
      PrevVoyage: data.PrevVoyage ? data.PrevVoyage : ""
    }

  });
  return response
}
export async function FindVoyagesWithPolPodForINV(data, auth) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "voyage/find-voyages-with-pol-pod-for-s-i",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: {
      POL: data.POL,
      POD: data.POD,
    }

  });
  return response
}

export async function FilterQuotations(data, auth, type) {
  var BRDocDate = data.DocDate
  var BRLastValidDate = data.LastValidDate
  var filters = {
    POLPortCode: data.filter.POLPortCode,
    FinalDestinationPortCode: data.filter.FinalDestinationPortCode,
    PODPortCode: data.filter.PODPortCode,
    QuotationBillTo: data.filter.QuotationBillTo,
    QuotationType: data.filter.QuotationType,
    QuotationHasContainerType: data.QuotationHasContainerType,
    filtersTranshipment: data.filtersTranshipment,
    filtersAgent: data.filtersAgent.ROC,
    filtersShipper: data.filtersShipper.ROC,
    filtersConsignee: data.filtersConsignee.ROC,
    VoyageNum: data.VoyageNum,
  }
  var Link;
  if (type == undefined) {
    Link = "quotation";
  } else if (type == "barge") {
    Link = "quotation-barge";
  } else {
    Link = "quotation";
  }

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + Link + "/filter-quotations",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { filters, BRDocDate, BRLastValidDate }

  });
  return response
}

export async function Preview(auth, model, id) {
  


  const response = await axios.get(auth.globalHost + auth.globalPathLink + model + "/preview?id=" + id, {
    method: "GET",
    responseType: 'arraybuffer',
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })

  return response
}
export async function PreviewLetter(auth, model, id) {
  const response = await axios.get(auth.globalHost + auth.globalPathLink + model + "/preview-letter?id=" + id, {
    method: "GET",
    responseType: 'arraybuffer',
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })

  return response
}


export async function PreviewMTCRO(auth, model, id) {
  const response = await axios.get(auth.globalHost + auth.globalPathLink + model + "/preview-m-t?id=" + id, {
    method: "GET",
    responseType: 'arraybuffer',
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })

  return response
}

export async function PreviewMTContainerReturn(auth, model, data) {
  const formData = new FormData();

  $.each(data, function (key, value) {
    formData.append(`data[${key}][BookingUUID]`, value.BookingUUID)
    formData.append(`data[${key}][ContainerUUID]`, value.ContainerUUID)
  })
  const response = await axios.post(auth.globalHost + auth.globalPathLink + model + "/preview-m-t", formData, {
    method: "POST",
    responseType: 'arraybuffer',
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })

  return response
}

export async function PreviewEmptyContainerReturn(auth, model, data) {
  const formData = new FormData();

  $.each(data, function (key, value) {
    formData.append(`data[${key}][BLUUID]`, value.BLUUID)
    formData.append(`data[${key}][ContainerUUID]`, value.ContainerUUID)
  })
  const response = await axios.post(auth.globalHost + auth.globalPathLink + model + "/preview-empty", formData, {
    method: "POST",
    responseType: 'arraybuffer',
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })

  return response
}



export async function PreviewQT(auth, model, id, type,type2) {
  const response = await axios.get(auth.globalHost + auth.globalPathLink + model + "/preview?id=" + id + "&type=" + type+"&type2="+type2, {
    method: "GET",
    responseType: 'arraybuffer',
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })

  return response
}

export async function PreviewBR(auth, model, id,confirmation) {
  var confirmState=0;
  if(confirmation){
    confirmState=1;
  }
  const response = await axios.get(auth.globalHost + auth.globalPathLink + model + "/preview?id=" + id+"&Confirm="+confirmState, {
    method: "GET",
    responseType: 'arraybuffer',
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })

  return response
}

export async function PreviewOR(auth, model, id, type, template) {
  const response = await axios.get(auth.globalHost + auth.globalPathLink + model + "/preview?id=" + id + "&type=" + type + "&template=" + template, {
    method: "GET",
    responseType: 'arraybuffer',
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })

  return response
}

export async function PreviewMultipleOR(auth, model, id, type,template) {
  const DocUUIDs = id
  const formData = new FormData();

    for (const uuid of DocUUIDs) {
      formData.append('CustomerPaymentUUIDs[]', uuid);
    }


  const response = await axios.post(auth.globalHost + auth.globalPathLink + model + "/multiple-preview?type=" + type + "&template=" + template, formData,{
    method: "POST",
    responseType: 'arraybuffer',
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })

  return response
}

export async function PreviewBC(auth, model, id) {
  const response = await axios.get(auth.globalHost + auth.globalPathLink + model + "/preview-confirmation?id=" + id, {
    method: "GET",
    responseType: 'arraybuffer',
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })

  return response
}

export async function PreviewBillOfLading(auth, model, id, reportType) {
  const response = await axios.get(auth.globalHost + auth.globalPathLink + model + "/preview?id=" + id + "&ReportType=" + reportType, {
    method: "GET",
    responseType: 'arraybuffer',
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })

  return response
}

export async function PreviewMultipleBL(auth, model, id, reportType) {

  const BillOfLadingUUIDs = id
  const formData = new FormData();
  var tpFlag=""

  for (const uuid of BillOfLadingUUIDs) {
    formData.append('BillOfLadingUUIDs[]', uuid);
  }
  if((window.location.pathname.includes("third-party"))){
    tpFlag="&ThirdParty=1"
  }
  const response = await axios.post(auth.globalHost + auth.globalPathLink + model + "/multiple-preview?ReportType=" + reportType+tpFlag, formData, {
    method: "POST",
    responseType: 'arraybuffer',
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })

  return response
}

export async function PreviewMultipleDO(auth, model, id, reportType) {
  const DeliveryOrderUUIDs = id
  const formData = new FormData();

  for (const uuid of DeliveryOrderUUIDs) {
    if (uuid) {
      formData.append('DeliveryOrderUUIDs[]', uuid);
    }

  }

  const response = await axios.post(auth.globalHost + auth.globalPathLink + model + "/multiple-preview?ReportType=" + reportType, formData, {
    method: "POST",
    responseType: 'arraybuffer',
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })

  return response
}

export async function PreviewINVCNDN(auth, model, id, reportType) {
  var tpFlag=""
  if((window.location.pathname.includes("third-party"))){
    tpFlag="&ThirdParty=1"
  }
  const response = await axios.get(auth.globalHost + auth.globalPathLink + model + "/preview?id=" + id + "&ReportType=" + reportType+tpFlag, {
    method: "GET",
    responseType: 'arraybuffer',

    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })

  return response
}

export async function PreviewCA(auth, model, id, reportType,type,proformaType) {
  const DocUUIDs = id
  const formData = new FormData();
  var tpFlag=""
  var proformaFlag=""

 
  
    for (const uuid of DocUUIDs) {
      formData.append('CreditAdviceUUIDs[]', uuid);
    }
  
 
  if((window.location.pathname.includes("third-party"))){
    tpFlag="&ThirdParty=1"
  }

  const response = await axios.post(auth.globalHost + auth.globalPathLink + model + "/multiple-preview?ReportType=" + type + "&type=" + reportType+tpFlag+proformaFlag, formData, {
    method: "POST",
    responseType: 'arraybuffer',
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })

  return response
}



export async function PreviewMultiplCNDN(auth, model, id, reportType,type,proformaType) {
  const DocUUIDs = id
  const formData = new FormData();
  var tpFlag=""
  var proformaFlag=""

  if(model=="credit-note" || model=="credit-note-barge"){
    for (const uuid of DocUUIDs) {
      formData.append('SalesCreditNoteUUIDs[]', uuid);
    }
  }

  if(model=="debit-note" || model=="debit-note-barge"){
    for (const uuid of DocUUIDs) {
      formData.append('SalesDebitNoteUUIDs[]', uuid);
    }
  }
 
  if((window.location.pathname.includes("third-party"))){
    tpFlag="&ThirdParty=1"
  }

  const response = await axios.post(auth.globalHost + auth.globalPathLink + model + "/multiple-preview?ReportType=" + type + "&type=" + reportType+tpFlag+proformaFlag, formData, {
    method: "POST",
    responseType: 'arraybuffer',
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })

  return response
}


export async function PreviewMultipleInvoice(auth, model, id, reportType, type,proformaType) {
  const SalesInvoiceUUIDs = id
  const formData = new FormData();
  var tpFlag=""
  var proformaFlag=""

  for (const uuid of SalesInvoiceUUIDs) {
    formData.append('SalesInvoiceUUIDs[]', uuid);
  }

  if((window.location.pathname.includes("third-party"))){
    tpFlag="&ThirdParty=1"
  }

  if(proformaType){
    proformaFlag="&Proforma=1"
  }
  const response = await axios.post(auth.globalHost + auth.globalPathLink + model + "/multiple-preview?ReportType=" + type + "&type=" + reportType+tpFlag+proformaFlag, formData, {
    method: "POST",
    responseType: 'arraybuffer',
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })

  return response
}

export async function PreviewMultipleDebitAdvice(auth, model, id, reportType, type,proformaType) {
  const DebitAdviceUUIDs = id
  const formData = new FormData();
  var tpFlag=""
  var proformaFlag=""

  for (const uuid of DebitAdviceUUIDs) {
    formData.append('DebitAdviceUUIDs[]', uuid);
  }

  if((window.location.pathname.includes("third-party"))){
    tpFlag="&ThirdParty=1"
  }

  if(proformaType){
    proformaFlag="&Proforma=1"
  }
  const response = await axios.post(auth.globalHost + auth.globalPathLink + model + "/multiple-preview?ReportType=" + type + "&type=" + reportType+tpFlag+proformaFlag, formData, {
    method: "POST",
    responseType: 'arraybuffer',
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })

  return response
}

export async function FindAllocation(data, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "booking-reservation/find-allocation",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: {
      VoyageUUID: data.allocateVoyage,
      POL: data.allocatePortCode,
      VoyageNo: data.StrvoyageNo,
      VoyageScheduleUUID: data.voyageScheduleUUID,
    }

  });
  return response
}

export async function ContainerTransshipmentTransfer(data, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "container-transshipment/transfer",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: {
      VoyageUUID: data.VoyageUUID,
      BookingReservationUUID: data.BookingReservationUUID,
      FromVoyageETA: data.FromVoyageETA,
      BookingReservationHasTranshipmentPortCode: data.BookingReservationHasTranshipmentPortCode,
    }

  });
  return response
}


export async function getVoyageByIdSpecial(result, auth) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "voyage/get-voyage-by-id-special?id=" + result,
    dataType: "json",
    async: false,
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}

export async function FindDepotCompany(data, auth) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "company/find-depot-company?UserPort=" + data,
    dataType: "json",
    async: false,
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}

export async function FindDepotBranch(company, port, auth) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "company/find-depot-branch?UserPort=" + port + "&CompanyUUID=" + company,
    dataType: "json",
    async: false,
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}
export async function CheckVoyage(data, auth) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "voyage/check-voyage?id=" + data,
    dataType: "json",
    async: false,
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}

export async function getVesselById(data, auth) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "vessel/get-vessel-by-id?id=" + data,
    dataType: "json",
    async: false,
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },

  });
  return response
}

export async function FindVoyages(result, auth) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "voyage/find-voyages",
    dataType: "json",
    async: false,
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { filters: result },
  });
  return response
}

export async function FindVoyageSuggestion(result, auth) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "voyage/find-voyage-suggestions",
    dataType: "json",
    async: false,
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { POD: result.POD, POL: result.POL, POLReqETA: result.POLReqETA, PODReqETA: result.PODReqETA },
  });
  return response
}


export async function FindRemainingBR(auth) {

  const response = await $.ajax({
    type: "GET",
    url: auth.globalHost + auth.globalPathLink + "container-release-order/find-remaining-b-r",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function FindRemainingBC(auth, type,docType) {
  var Link;
  if (type == undefined) {
    Link = "booking-confirmation";
  } else if (type == "barge") {
    Link = "booking-confirmation-barge";
  } else {
    Link = "booking-confirmation";
  }

  const response = await $.ajax({
    type: "GET",
    url: auth.globalHost + auth.globalPathLink + Link + "/get-remaining-booking-confirmation?type="+docType,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function FindRemainingQT(auth, type,docType) {
  var Link;
  if (type == undefined) {
    Link = "sales-invoice";
  } else if (type == "barge") {
    Link = "sales-invoice-barge";
  } else {
    Link = "sales-invoice";
  }

  const response = await $.ajax({
    type: "GET",
    url: auth.globalHost + auth.globalPathLink + Link + "/get-remaining-quotation?type="+docType,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}



export async function FindBLRemainingBR(auth, type,docType) {
  var Link;
  if (type == undefined) {
    Link = "bill-of-lading";
  } else if (type == "barge") {
    Link = "bill-of-lading-barge";
  } else {
    Link = "bill-of-lading";
  }
  const response = await $.ajax({
    type: "GET",
    url: auth.globalHost + auth.globalPathLink + Link + "/find-remaining-b-r?type="+docType,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function getChargesByContainerTypeAndPortCode(ContainerType, UserPortCode, auth, voyageUUID, async) {
  if (async == undefined) {
    async = true;
  }
  if(voyageUUID){
    voyageUUID=voyageUUID.split('@')[0]
  }
  const response = await $.ajax({
    type: "POST",
    async: async,
    url: auth.globalHost + auth.globalPathLink + "charges/get-charges-by-container-type-and-port-code?ContainerType=" + ContainerType + "&PortCode=" + UserPortCode + "&VoyageUUID=" + voyageUUID,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function getChargesByContainerTypeAndPOLPODPortCode(ContainerType, UserPortCode, auth, voyageUUID, async, POLPortCodeUUID, PODPortCodeUUID, POTPortCodeUUID,VesselUUID,Model,BargeCodeUUID) {
  if (async == undefined) {
    async = true;
  }
  if(voyageUUID){
    voyageUUID=voyageUUID.split('@')[0]
  }
  if(!BargeCodeUUID){
    BargeCodeUUID=""
  }
  var portCodeArray = []
  POLPortCodeUUID && portCodeArray.push(POLPortCodeUUID)
  PODPortCodeUUID && portCodeArray.push(PODPortCodeUUID)

  var conCatArray = portCodeArray.concat(POTPortCodeUUID)

  const response = await $.ajax({
    type: "POST",
    async: async,
    url: auth.globalHost + auth.globalPathLink + "charges/get-charges-by-container-type-and-port-code2",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { ContainerType: ContainerType, PortCode: conCatArray, VoyageUUID: voyageUUID,VesselUUID:VesselUUID,Model:Model,BargeCodeUUID:BargeCodeUUID}
  });
  return response
}

export async function getChargesByContainerTypeAndPOLPODPortCodeQuotation(ContainerType, UserPortCode, auth, voyageUUID, async, POLPortCodeUUID, PODPortCodeUUID, POTPortCodeUUID) {
  if (async == undefined) {
    async = true;
  }
  if(voyageUUID){
    voyageUUID=voyageUUID.split('@')[0]
  }
  var portCodeArray = []
  POLPortCodeUUID && portCodeArray.push(POLPortCodeUUID)

  var conCatArray = portCodeArray.concat(POTPortCodeUUID, PODPortCodeUUID)


  const response = await $.ajax({
    type: "POST",
    async: async,
    url: auth.globalHost + auth.globalPathLink + "charges/get-charges-by-container-type-and-port-code2",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { ContainerType: ContainerType, PortCode: conCatArray, VoyageUUID: voyageUUID }
  });
  return response
}

export async function getContainerTypeById(id, auth) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "universal/get-container-type?id=" + id,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function getChargesWithPortCodeAndEmptyContainerType(id, auth,VesselUUID,BargeUUID,model) {
  if(id){
    id=id.split("@")[0]
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "universal/get-charges-with-port-code-and-empty-container-type?VoyageUUID=" + id+"&VesselUUID="+VesselUUID+"&BargeUUID="+BargeUUID+"&Model="+model,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function getChargesWithPortCodeAndEmptyContainerType2(id, portCode, auth,model) {
  if(id){
    id=id.split("@")[0]
  }
  var portCodeId = portCode.join(",")
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "universal/get-charges-with-port-code-and-empty-container-type2?VoyageUUID=" + id + "&PortCode=" + portCodeId+"&Model="+model,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function getTransferFromQuotationData(id, auth, podPortCode, fDPortCode, type) {
  fDPortCode ? fDPortCode = fDPortCode : fDPortCode = ""
  var Link;
  if (type == undefined) {
    Link = "booking-reservation";
  } else if (type == "barge") {
    Link = "booking-reservation-barge";
  } else {
    Link = "booking-reservation";
  }

  const response = await axios.get(auth.globalHost + auth.globalPathLink + Link + "/transfer-from-quotation-data?id=" + id + "&Port=" + podPortCode + "&FDPort=" + fDPortCode, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function getTransferSalesInvoiceFromQuotationData(id, auth, podPortCode, fDPortCode, type) {
  fDPortCode ? fDPortCode = fDPortCode : fDPortCode = ""
  var Link;
  if (type == undefined) {
    Link = "booking-reservation";
  } else if (type == "barge") {
    Link = "booking-reservation-barge";
  } else {
    Link = "booking-reservation";
  }

  const response = await axios.get(auth.globalHost + auth.globalPathLink + Link + "/transfer-from-quotation-data?id=" + id + "&Port=" + podPortCode + "&FDPort=" + fDPortCode, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function getSplitDataBR(data, auth) {
  const response = await axios.get(auth.globalHost + auth.globalPathLink + "booking-reservation/get-split-data?SplitID=" + data.containerTypeID + "&id=" + data.id + "&ContainerID=" + data.splitID, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function getEinvoiceHistory(auth, model, id) {
  const response = await axios.get(auth.globalHost + auth.globalPathLink + `${model}/get-e-invoicing-history?id=${id}`, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function getCROTransferFromBooking(id, auth) {
  const response = await axios.get(auth.globalHost + auth.globalPathLink + "container-release-order/transfer-from-booking-reservation-data?id=" + id, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function getINVTransferFromBooking(id, CustomerType, BillTo, ChargesID, auth, model, type,draft) {
  var Link;
  if (type == undefined) {
    Link = "sales-invoice";
  } else if (type == "barge") {
    Link = "sales-invoice-barge";
  } else {
    Link = "sales-invoice";
  }

  var stringChargesID = ChargesID ? ChargesID.join(",") : ""
  const response = await axios.get(auth.globalHost + auth.globalPathLink + Link + "/transfer-from-booking-confirmation-data?id=" + id + "&BillTo=" + BillTo + "&CustomerType=" + CustomerType +"&Draft="+draft+"&ChargesID=" + stringChargesID, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function getINVTransferFromQuotation(id,formstate,auth, type) {

 
  var Link;
  var draft=0;
  if (type == undefined) {
    Link = "sales-invoice";
  } else if (type == "barge") {
    Link = "sales-invoice-barge";
  } else {
    Link = "sales-invoice";
  }
  // var stringChargesID = ChargesID ? ChargesID.join(",") : ""
  const response = await axios.get(auth.globalHost + auth.globalPathLink + Link + "/transfer-from-quotation-data?id=" + id + "&BillTo=" + formstate.BillTo + "&CustomerType=" + formstate.CustomerType +"&Draft="+draft+"&ChargesID=" + "&Port="+formstate.PODPort +"&FDPort="+formstate.FDPort, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function getConsolidateInvoice(auth, model, data, type) {

  if (type == undefined) {
    model = model
  } else if (type == "barge") {
    model = model + "-barge"
  } else {
    model = model
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/get-generate-consolidate-sales-invoice-data",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}
export async function getConsolidateInvoiceCN(auth, model, data, type) {

  if (type == undefined) {
    model = model
  } else if (type == "barge") {
    model = model + "-barge"
  } else {
    model = model
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/get-generate-consolidate-sales-credit-note-data",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}

export async function getConsolidateInvoiceDN(auth, model, data, type) {

  if (type == undefined) {
    model = model
  } else if (type == "barge") {
    model = model + "-barge"
  } else {
    model = model
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/get-generate-consolidate-sales-debit-note-data",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}

export async function getBLTransferFromBooking(id, auth, model, type) {
  var Link;
  if (type == undefined) {
    Link = "bill-of-lading";
  } else if (type == "barge") {
    Link = "bill-of-lading-barge";
  } else {
    Link = "bill-of-lading";
  }
  const response = await axios.get(auth.globalHost + auth.globalPathLink + Link + "/transfer-from-booking-reservation-data?id=" + id, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function GetSplitBL(id, auth, shareID, splitID) {
 
  const response = await axios.get(auth.globalHost + auth.globalPathLink + "bill-of-lading/get-b-r-split-share-data?id=" + id + "&SplitID=" + splitID + "&ShareID=" + shareID, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function GetSplitDO(id, auth, shareID, splitID) {
  const response = await axios.get(auth.globalHost + auth.globalPathLink + "delivery-order/get-d-o-split-share-data?id=" + id + "&SplitID=" + splitID + "&ShareID=" + shareID, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function GetMergeBL(id, auth, mergeIDs) {
  const response = await axios.get(auth.globalHost + auth.globalPathLink + "bill-of-lading/get-merge-bill-of-lading-containers-data?id=" + id + "&mergeIDs=" + mergeIDs, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function GetMergeINV(id, auth, mergeIDs, type,docNum) {


  if(docNum){
    
    const response = await axios.get(auth.globalHost + auth.globalPathLink + type + "/get-merge-sales-invoice-containers-data?id=" + id + "&mergeIDs=" + mergeIDs, {
      auth: {
        username: auth.authInfo.username,
        password: auth.authInfo.access_token,
      },
    })
    return response

  }else{
    const newMergeID=mergeIDs+","+id
    const response = await axios.get(auth.globalHost + auth.globalPathLink + type + "/get-merge-sales-invoice-containers-data?id=&mergeIDs=" + newMergeID, {
      auth: {
        username: auth.authInfo.username,
        password: auth.authInfo.access_token,
      },
    })
    return response
  }


 
}

export async function GetMergeBR(id, auth, mergeIDs) {
  const response = await axios.get(auth.globalHost + auth.globalPathLink + "booking-reservation/get-merge-booking-reservation-data?id=" + id + "&mergeIDs=" + mergeIDs, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function GetMergeBLList(id, auth, pOLPortCode, pODportCode, voyageNum) {
  const response = await axios.get(auth.globalHost + auth.globalPathLink + "bill-of-lading/get-merge-bill-of-lading-list?id=" + id + "&PODPortCode=" + pODportCode + "&POLPortCode=" + pOLPortCode + "&VoyageNum=" + voyageNum, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function GetMergeBRList(id, auth, POLCodeMerge, PODCodeMerge, VoyageNumMerge, qtNo) {
  const response = await axios.get(auth.globalHost + auth.globalPathLink + "booking-reservation/get-b-r-by-pol-pod-voyage?id=" + id + "&POL=" + POLCodeMerge + "&POD=" + PODCodeMerge + "&VoyageNum=" + VoyageNumMerge + "&QuotationUUID=" + qtNo, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function GetMergeINVList(id, auth, billtTo, type) {

  const response = await axios.get(auth.globalHost + auth.globalPathLink + type + "/get-merge-sales-invoice-list?id=" + id + "&BillTo=" + billtTo, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}
export async function SplitShareContainer(auth, formData, id, splitID, shareID) {
  const response = await axios.post(auth.globalHost + auth.globalPathLink + "bill-of-lading/split-share-containers?SplitID=" + splitID + "&ShareID=" + shareID + "&id=" + id, formData, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}
export async function SplitShareDOContainer(auth, formData, id, splitID, shareID) {
  const response = await axios.post(auth.globalHost + auth.globalPathLink + "delivery-order/split-share-containers?SplitID=" + splitID + "&ShareID=" + shareID + "&id=" + id, formData, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}
export async function SplitContainerBR(auth, formData, id, containerID) {
  const response = await axios.post(auth.globalHost + auth.globalPathLink + "booking-reservation/split-containers?id=" + id + "&BRContainerID=" + containerID, formData, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function MergeBillOfLading(auth, formData, id, mergeIDs) {
  const response = await axios.post(auth.globalHost + auth.globalPathLink + "bill-of-lading/merge-bill-of-lading?id=" + id + "&mergeIDs=" + mergeIDs, formData, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function MergeSalesInvoice(auth, formData, id, mergeIDs, type) {
  const response = await axios.post(auth.globalHost + auth.globalPathLink + type + "/merge-sales-invoice?id=" + id + "&mergeIDs=" + mergeIDs, formData, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function MergeBookingReservation(auth, formData, id, mergeIDs) {
  const response = await axios.post(auth.globalHost + auth.globalPathLink + "booking-reservation/merge-booking-reservation-data?id=" + id + "&mergeIDs=" + mergeIDs, formData, {
    auth: {
      username: auth.authInfo.username,
      password: auth.authInfo.access_token,
    },
  })
  return response
}

export async function TelexRelease(auth, model, data, type) {
  if (type == undefined) {
    model = model
  } else if (type == "barge") {
    model = model + "-barge"
  } else {
    model = model
  }

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/telex-release2",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}

export async function RevertSplitBL(auth, model, data) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/revert-split?id=" + data,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}

export async function RevertMergeINV(auth, model, data) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/revert-merge?id=" + data,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function GetBillOfLadingContainers(auth, model, data) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/get-bill-of-lading-containers?id=" + data,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function GetBookingReservationsContainers(auth, model, data) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/get-booking-reservation-containers?id=" + data,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function GetDeliveryOrderContainers(auth, model, data) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/get-delivery-order-containers?id=" + data,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function CheckingMergeBookingReservation(auth, id, mergeIDs) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "booking-reservation/merge-booking-reservation?id=" + id + "&mergeIDs=" + mergeIDs,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function getBCChargesDescription(data, auth) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "sales-invoice/get-b-c-charges-description",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: {
      ContainerType: data
    },
  });
  return response
}

export async function getParentInvoiceDropdown(data, auth,type,moduleType) {
  var Link;
  if (type == undefined) {
    Link = "sales-invoice";
  } else if (type == "barge") {
    Link = "sales-invoice-barge";
  } else {
    Link = "sales-invoice";
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + Link+"/get-sales-invoice-dropdown-data?id=" + data+"&moduleType="+moduleType,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}
export async function GetUser(data, auth) {

  var filters = {
    "id": data
  };

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "user/get-users",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { filters: filters },

  });
  return response
}

export async function CheckBoxHandle(e) {
  var id = $(e.target).attr("id")
  if (e.target.checked) {
    $(e.target).parent().find(":hidden").val("1")
    $(e.target).parent().find("input:text").val("1")
  } else {
    $(e.target).parent().find(":hidden").val("0")
    $(e.target).parent().find("input:text").val("0")
  }
}

export async function GetBookingReservationContainerQty(id, auth) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "container-release-order/get-booking-reservation-container-qty",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { BookingReservation: id }

  });
  return response
}

export async function GetUpdateDND(data, model, auth) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/update-dnd",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: {
      [data.Type]: data.Selection,
      ApplyDND: data.ApplyDND,
      DNDCombined: data.DNDCombined,
      DNDCombinedDay: data.DNDCombinedDay,
      Detention: data.Detention,
      Demurrage: data.Demurrage,
    }
  });
  return response
}

export async function EditDND(data,auth) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "booking-reservation/edit-d-n-d",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: {
      data
    }
  });
  return response
}

export async function CheckDOStatus(auth, model, data) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/generate-d-o",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}

export async function ReleaseDO(auth, model, data) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/release-d-o",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}

export async function ReleaseBL(auth, model, data) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/release-b-l",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}


export async function GetVoyageDelay(auth, model, data) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/get-voyage-delay-data?id=" + data,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function SubmitVoyage(auth, model, data) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/submit-xml?id=" + data,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function RetrieveVoyage(auth, model, data) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/retrieve?id=" + data,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function CheckVoyageDelay(auth, model, id, data) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/check-voyage-delay?id=" + id,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}

export async function CheckVoyageEffectedDocument(auth, model, id, data) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/effected-documents?id=" + id,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { VoyageSchedulesData: data },
  });
  return response
}

export async function ImportContainerCRO(auth, depot, data,old) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "container-release-order/read-excel?Depot=" + depot+"&Old="+old,
    dataType: "json",
    processData: false,  // tell jQuery not to process the data
    contentType: false,  // tell jQuery not to set contentType
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}

export async function ImportContainerMultipleCRO(auth,data) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "container-release-order/upload-excel",
    dataType: "json",
    processData: false,  // tell jQuery not to process the data
    contentType: false,  // tell jQuery not to set contentType
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}

export async function ImportContainer(auth, data) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "container/import-container-excel",
    dataType: "json",
    processData: false,  // tell jQuery not to process the data
    contentType: false,  // tell jQuery not to set contentType
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}

export async function DownloadExcel(auth, data) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "container/download-excel",
    dataType: "json",
    processData: false,  // tell jQuery not to process the data
    contentType: false,  // tell jQuery not to set contentType
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  if (response.message === "Success" && response.data) {
    return response.data;
  } else {
    throw new Error("Failed to retrieve response");
  }
}

export async function GetRemainingInvoice(auth, model, data,docType) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/get-remaining-sales-invoices",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { BranchCode: data,Type:docType?docType.toUpperCase():""},
  });
  return response
}

export async function GetRemainingDebitAdvice(auth, model, data,docType) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/get-remaining-debit-advices",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { BranchCode: data,Type:docType?docType.toUpperCase():""},
  });
  return response
}

export async function GetRemainingQuotationById(id, auth,port,type) {
  var Link;

  if (type == undefined) {
    Link = "sales-invoice";
  } else if (type == "barge") {
    Link = "sales-invoice-barge";
  } else {
    Link = "sales-invoice";
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + Link + "/get-remaining-quotation-by-id?id=" + id+"&Port="+port,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function CheckTransferPartialBillTo(data, auth) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "sales-invoice/check-transfer-partial-bill-to",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { BCContainerTypes: data },
  });
  return response
}

export async function LoadPartialBCById(id, auth, type) {
  var Link;
  if (type == undefined) {
    Link = "sales-invoice";
  } else if (type == "barge") {
    Link = "sales-invoice-barge";
  } else {
    Link = "sales-invoice";
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + Link + "/load-partial-booking-confirmation?id=" + id,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function TransferToCreditNote(id, auth, type) {
  var Link;
  if (type == undefined) {
    Link = "sales-invoice";
  } else if (type == "barge") {
    Link = "sales-invoice-barge";
  } else {
    Link = "sales-invoice";
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + Link + "/transfer-to-credit-note-get-remaining-charges-data?id=" + id,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function CheckTransferedCharges(id, auth, barge, array) {
  var Link;
  barge ? Link = "credit-note-barge" : Link = "credit-note"

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + Link + "/check-transferred-charges?id=" + id,
    dataType: "json",
    data: { ChargesContainer: array },
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function CheckTransferedChargesCA(id, auth, barge, array) {
  var Link;
  barge ? Link = "credit-advice-barge" : Link = "credit-advice"

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + Link + "/check-transferred-charges?id=" + id,
    dataType: "json",
    data: { ChargesContainer: array },
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}


export async function TransferToDebitNote(id, auth, type) {
  var Link
  if (type == undefined) {
    Link = "sales-invoice";
  } else if (type == "barge") {
    Link = "sales-invoice-barge";
  } else {
    Link = "sales-invoice";
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + Link + "/transfer-to-debit-note-get-remaining-charges-data?id=" + id,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function TransferToCreditAdvice(id, auth, type) {
  var Link
  if (type == undefined) {
    Link = "debit-advice";
  } else if (type == "barge") {
    Link = "debit-advice-barge";
  } else {
    Link = "debit-advice";
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + Link + "/transfer-to-credit-advice-get-remaining-charges-data?id=" + id,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function getRemainingBCbyID(id, auth, type) {
  var Link;

  if (type == undefined) {
    Link = "booking-confirmation";
  } else if (type == "barge") {
    Link = "booking-confirmation-barge";
  } else {
    Link = "booking-confirmation";
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + Link + "/get-remaining-booking-confirmation-by-id?id=" + id,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function checkBCTransfer(id, auth, type) {
  var Link;
  if (type == undefined) {
    Link = "booking-confirmation";
  } else if (type == "barge") {
    Link = "booking-confirmation-barge";
  } else {
    Link = "booking-confirmation";
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + Link + "/check-booking-confirmation-transfer?id=" + id,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function getBookingConfirmationHasContainerType(id, auth, type) {
  var Link;
  if (type == undefined) {
    Link = "booking-confirmation";
  } else if (type == "barge") {
    Link = "booking-confirmation-barge";
  } else {
    Link = "booking-confirmation";
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + Link + "/get-booking-confirmation-has-container-type?id=" + id,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function GetCNDNTransferFromSalesInvoice(id, model, auth, array) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/get-transfer-from-sales-invoice-data?id=" + id,
    dataType: "json",
    data: { ChargesContainer: array },
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function GetCNDNTransferFromDebitAdvice(id, model, auth, array) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/get-transfer-from-debit-advice-data?id=" + id,
    dataType: "json",
    data: { ChargesContainer: array },
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}


export async function GetRuleSetRule(id, model, auth,) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/get-rule-set-rules?id=" + id,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function GetRuleSetUers(id, model, auth,) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/get-rule-set-users?id=" + id,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function GetUserRuleByRuleSet(id, model, auth, data) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/get-user-by-rule-set?id=" + id,
    dataType: "json",
    data: {
      Scope: data.Scope,
      Port: data.Port,
      FreightTerm: data.FreightTerm,
      rules: data.rules
    },
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function UpdateUserRule(id, model, auth, data1) {

  var data = JSON.stringify(data1);
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/update-user-rules?id=" + id,
    dataType: "json",
    data: {
      data
    },
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function UpdateRuleSetUers(id, model, auth, data) {
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/update-rule-set-users?id=" + id,
    dataType: "json",
    data: {
      users: data,
    },
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function UpdateUserRuleByRuleSet(id, model, auth, data) {
  if (data.users) {
    const response = await $.ajax({
      type: "POST",
      url: auth.globalHost + auth.globalPathLink + model + "/update-rule-set-rules?id=" + id,
      dataType: "json",
      data: {
        Scope: data.Scope,
        Port: data.Port,
        FreightTerm: data.FreightTerm,
        rules: data.rules,
        users: data.users
      },
      headers: {
        "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
      },
    });
    return response
  } else {
    const response = await $.ajax({
      type: "POST",
      url: auth.globalHost + auth.globalPathLink + model + "/update-rule-set-rules?id=" + id,
      dataType: "json",
      data: {
        Scope: data.Scope,
        Port: data.Port,
        FreightTerm: data.FreightTerm,
        rules: data.rules
      },
      headers: {
        "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
      },
    });
    return response
  }


}

export async function GetAllPendingDocumentAll(auth, data) {
  var param = {
    limit: data.limit,
    offset: data.offset,
    type: data.type
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "site/get-pending-documents",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { param: param },
  });
  return response
}

export async function GetVoyageDelayDocuments(auth, data) {
  var param = {
    limit: data.limit,
    offset: data.offset,
    type: data.type
  }
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "voyage/get-voyage-delay-documents",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: { param: param },
  });
  return response
}

export async function GetSuitableBRForBL(auth, id) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "bill-of-lading/find-booking-reservation?id=" + id,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function GetSuitableVoyageForMyVoyage(auth, id) {

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + "manifest-import/get-other-voyages-drop-down-data?VoyageUUID=" + id,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
  });
  return response
}

export async function SaveManifestImportVoyageNo(auth, BLuuids, voyageid,type) {
  var latestData;
  var subLink
  if(type=="DO"){
    latestData={ ManifestImportVoyageNo: voyageid,  DeliveryOrderUUIDs: BLuuids}
    subLink="manifest-import/save-manifest-import-voyage-no-d-o"
  }else{
    latestData={ ManifestImportVoyageNo: voyageid,  BillOfLadingUUIDs: BLuuids}
    subLink="manifest-import/save-manifest-import-voyage-no"
  }

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + subLink,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data:latestData,
  });
  return response
}

export async function getCheckTransferOceanBL(auth, model, data, type) {
  if (type == undefined) {
    model = model
  } else if (type == "barge") {
    model = model + "-barge"
  } else {
    model = model
  }

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/check-ocean-b-l?id=" + data,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}

export async function getCheckTransferHouseBL(auth, model, data, type) {
  if (type == undefined) {
    model = model
  } else if (type == "barge") {
    model = model + "-barge"
  } else {
    model = model
  }

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/check-house-b-l?id=" + data,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}

export async function getCheckTransferHouseDO(auth, model, data, type) {
  if (type == undefined) {
    model = model
  } else if (type == "barge") {
    model = model + "-barge"
  } else {
    model = model
  }

  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + model + "/check-house-d-o?id=" + data,
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: data,
  });
  return response
}


export async function PublicGetAllPortCode(auth, model) {

  const response = await axios.post(auth.globalHost + auth.globalPathLink + model + "/public-get-all-port-code", {

  })
  return response
}


export async function LoadTariff(data, auth, type) {

  var Link;
  if (type == undefined) {
    Link = "quotation";
  } else if (type == "barge") {
    Link = "quotation-barge";
  } else {
    Link = "quotation";
  }

  var SingleLoadTariff = data.SingleLoadTariff
  var ContainerType = data.ContainerType
  var DocDate = data.DocDate
  var POLPortCode = data.POLPortCode
  var POLAreaName = data.POLAreaName
  var POLPortTerm = data.POLPortTerm
  var PODPortCode = data.PODPortCode
  var PODAreaName = data.PODAreaName
  var PODPortTerm = data.PODPortTerm
  var ContainerOwnershipType = data.ContainerOwnershipType
  var DGClass = data.DGClass
  var MinQty = data.MinQty
  var BoxOpCo = data.BoxOpCo
  var BoxOpBranch = data.BoxOpBranch
  var ShipOpCo = data.ShipOpCo
  var ShipOpBranch = data.ShipOpBranch
  var Empty = data.Empty
  var CurrencyType = data.CurrencyType
  var VoyageUUID = data.VoyageUUID
  var BillTo = data.BillToCompany
  const response = await $.ajax({
    type: "POST",
    url: auth.globalHost + auth.globalPathLink + Link + "/load-tariff",
    dataType: "json",
    headers: {
      "Authorization": "Basic " + btoa(auth.authInfo.username + ":" + auth.authInfo.access_token)
    },
    data: {
      SingleLoadTariff,
      ContainerType,
      DocDate,
      POLPortCode,
      POLAreaName,
      POLPortTerm,
      PODPortCode,
      PODAreaName,
      PODPortTerm,
      ContainerOwnershipType,
      DGClass,
      MinQty,
      BoxOpCo,
      BoxOpBranch,
      ShipOpCo,
      ShipOpBranch,
      Empty,
      CurrencyType,
      VoyageUUID,
      BillTo
    }

  });
  return response
}

export function numberToWords(number) {
  var digit = ['ZERO', 'ONE', 'TWO', 'THREE', 'FOUR', 'FIVE', 'SIX', 'SEVEN', 'EIGHT', 'NINE'];
  var elevenSeries = ['TEN', 'EVELEN', 'TWELVE', 'THIRTEEN', 'FOURTEEN', 'FIFTEEN', 'SISTEEN', 'SEVENTEEN', 'EIGHTEEN', 'NINETEEN'];
  var countingByTens = ['TWENTY', 'THIRTY', 'FORTY', 'FIFTY', 'SIXTY', 'SEVENTY', 'EIGHTY', 'NINETY'];
  var shortScale = ['', 'THOUSAND', 'MILLION', 'BILLION', ''];

  number = number.toString(); number = number.replace(/[\, ]/g, ''); if (number != parseFloat(number)) return 'not a number'; var x = number.indexOf('.'); if (x == -1) x = number.length; if (x > 15) return 'too big'; var n = number.split(''); var str = ''; var sk = 0; for (var i = 0; i < x; i++) { if ((x - i) % 3 == 2) { if (n[i] == '1') { str += elevenSeries[Number(n[i + 1])] + ' '; i++; sk = 1; } else if (n[i] != 0) { str += countingByTens[n[i] - 2] + ' '; sk = 1; } } else if (n[i] != 0) { str += digit[n[i]] + ' '; if ((x - i) % 3 == 0) str += 'hundred '; sk = 1; } if ((x - i) % 3 == 1) { if (sk) str += shortScale[(x - i - 1) / 3] + ' '; sk = 0; } } if (x != number.length) { var y = number.length; str += 'point '; for (var i = x + 1; i < y; i++) str += digit[n[i]] + ' '; } str = str.replace(/\number+/g, ' ');
  return str.trim();

}

export async function InitAttachment(data, id) {
  window.$('#' + id).filer({
    showThumbs: true,
    addMore: true,
    allowDuplicates: false,
    theme: 'default',
    templates: {
      itemAppendToEnd: true,
      box: '<ul class="jFiler-items-list jFiler-items-default"></ul>',
      item: '<li class="jFiler-item">\
              <div class="jFiler-item-container">\
                <div class="jFiler-item-inner">\
                  <div class="jFiler-item-icon pull-left"></div>\
                    <div class="jFiler-item-info pull-left">\
                      <span class="jFiler-item-title" title="{{fi-name}}">{{fi-name | limitTo:30}}</span>\
                      <span class="jFiler-item-others">\
                        <span>| size: {{fi-size2}} | </span><span>type: {{fi-extension}}</span><span class="jFiler-item-status">{{fi-progressBar}}</span>\
                      </span>\
                      <div class="jFiler-item-assets">\
                        <ul class="list-inline">\
                          <li><a class="icon-jfi-trash jFiler-item-trash-action"></a></li>\
                      </ul>\
                    </div>\
                    <div><input type="hidden" name="Unit[AttachmentsName][]" value="{{fi-name}}"></div>\
                  </div>\
                </div>\
              </div>\
            </li>',
      itemAppend: '<li class="jFiler-item">\
      <div class="jFiler-item-container">\
        <div class="jFiler-item-inner">\
          <div class="jFiler-item-icon pull-left"></div>\
            <div class="jFiler-item-info pull-left">\
              <span class="jFiler-item-title" title="{{fi-name}}">{{fi-name | limitTo:30}}</span>\
              <span class="jFiler-item-others">\
                <span>| size: {{fi-size2}} | </span><span>type: {{fi-extension}}</span><span class="jFiler-item-status">{{fi-progressBar}}</span>\
              </span>\
              <div class="jFiler-item-assets">\
                <ul class="list-inline">\
                  <li><a href="{{fi-url}}" class="text-secondary" target="_blank"><i class="fa fa-search-plus"></i></a></li>\
                  <li><a href="{{fi-url}}" class="text-secondary" download><i class="fa fa-download"></i></a></li>\
                  <li><a class="icon-jfi-trash jFiler-item-trash-action"></a></li>\
              </ul>\
            </div>\
            <div><input type="hidden" name="Unit[AttachmentsName][]" value="{{fi-name}}"></div>\
          </div>\
        </div>\
      </div>\
    </li>',
    },
    files: data,
  });
  ControlOverlay(false)
}



//init display title when hover select field
export function initHoverSelectDropownTitle() {
  $('.select__control').on("mouseover", function (event) {
    if ($(this).children().find(".select__single-value").length > 0) {
      $(this).attr('title', $(this).find(".select__single-value").text());
    }

  });
}

export function initHoverSelectDropownTitleForReadonly() {
  
  var ArrayContainerName=[]
  $('.multipleContainerColumn').on("mouseover", function (event) {
   
    if ($(this).children().find(".react-select__control").find(".react-select__multi-value__label").length > 0) {

      var textArray = $(this).find(".react-select__control .react-select__multi-value__label").map(function() {
        return $(this).text();
      }).get();
       if(textArray){
        $(this).attr('title', textArray.join(","));
       }
    
    }

  });
  // $('.select__control').on("mouseover", function (event) {
  //   if ($(this).children().find(".select__single-value").length > 0) {
  //     $(this).attr('title', $(this).find(".select__single-value").text());
  //   }

  // });
}


export function toThreeDecimalPlaces() {
  $(".inputDecimalThreePlaces").on("blur", function () {
    if (this.value != "") {
      if (!this.value.match("^[a-zA-Z]*$")) {
        this.value = parseFloat(this.value).toFixed(3);
      }
      else {
        this.value = ""
      }

    }
  })
}

export function toTwoDecimalPlaces() {
  $(".inputDecimalTwoPlaces").on("blur", function () {
    if (this.value != "") {
      if (!this.value.match("^[a-zA-Z]*$")) {
        this.value = parseFloat(this.value).toFixed(3);
      }
      else {
        this.value = ""
      }

    }
  })
}

export function toFourDecimalPlaces() {
  $(".inputDecimalFourPlaces").on("blur", function () {
    if (this.value != "") {
      if (!this.value.match("^[a-zA-Z]*$")) {
        this.value = parseFloat(this.value).toFixed(4);
      }
      else {
        this.value = ""
      }

    }
  })
}

export function checkOnlyNumber() {
  $(".inputDecimalThreePlaces").on("blur", function () {
    if (this.value != "") {
      if (this.value.match("^[a-zA-Z]*$")) {
        return false
      }

    }
  })

  $(".inputDecimalFourPlaces").on("blur", function () {
    if (this.value != "") {
      if (this.value.match("^[a-zA-Z]*$")) {
        return false
      }

    }
  })
}

export function BracketSplitter(text) {
  const pattern = /\(([^)]+)\)/g;
  const matches = text.match(pattern);
  const textInBrackets = matches ? matches.map(match => match.slice(1, -1)) : "";

  return textInBrackets
}


export function replaceNull(someObj, replaceValue = "***") {
  const replacer = (key, value) =>
    String(value) === "null" || String(value) === "undefined" ? replaceValue : value;
  return JSON.parse(JSON.stringify(someObj, replacer));
}

export function setAutoHeigthTextArea(id) {
  var textarea = $('#' + id);

  function adjustTextareaHeight() {
    textarea.height('auto'); // Reset the height to auto to recalculate the actual height
    textarea.height(textarea[0].scrollHeight + 'px'); // Set the height to the scroll height
  }

  // Add event handlers for input and paste events
  textarea.on('input', adjustTextareaHeight);
  textarea.on('paste', adjustTextareaHeight);
}


export function sortArray(array) {

  function splitString(str) {
    const regex = /(\d+)$/;
    const match = regex.exec(str);

    if (match) {
      const index = match.index;
      const number = match[0];
      const prefix = str.slice(0, index);
      return [prefix, number];
    }
    return [str];
  }
  if (array) {
    const sortedData = array.sort((a, b) => {
      const regex = /^[a-zA-Z]/;
      const aSplitOut = splitString(a.label)
      const bSplitOut = splitString(b.label)

      if (!a.label && !b.label) {
        return 0;
      } else if (!a.label) {
        return 1;
      } else if (!b.label) {
        return -1;
      } else if (a.currencyType === "----942c4cf1-d709-11eb-91d3-b42e998d11ff" && b.currencyType !== "----942c4cf1-d709-11eb-91d3-b42e998d11ff") { //default ringgit malaysia on syscms
        return -1;
      } else if (a.currencyType !== "----942c4cf1-d709-11eb-91d3-b42e998d11ff" && b.currencyType === "----942c4cf1-d709-11eb-91d3-b42e998d11ff") {
        return 1;
      } else if (regex.test(a.label) && !regex.test(b.label)) {
        return -1;
      } else if (!regex.test(a.label) && regex.test(b.label)) {
        return 1;
      }
      else if (aSplitOut[0] == bSplitOut[0]) {
        if (aSplitOut.length === 1) {
          return -1;
        }
        if (bSplitOut.length === 1) {
          return 1;
        }
        if (parseInt(aSplitOut[1]) > parseInt(bSplitOut[1])) {
          return 1;
        } else {
          return -1;
        }
      } else {
        return a.label.localeCompare(b.label);
      }
    });
    return sortedData
  }



}

export function checkAuthApproval(data, module) {
  var empty = []
  if (data) {
    var foundData = data.find(item => item.Module == module)
    return foundData.Usage
  } else {
    return empty;
  }


}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
};
